import React from "react";
import { Link, NavLink } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";


const Breadcrumbs = ({prop}) => {

  const routes = [
    { path: "/", breadcrumb: "Home" },
    { path: "/contact/", breadcrumb: 'Contact Us' },
    { path: "/posts/", breadcrumb: 'News' },
    { path: "/posts/:id", breadcrumb: prop },
  ];

  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <>
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <NavLink key={match.pathname} to={match.pathname}>
          {breadcrumb}
        </NavLink>
      ))}
    </>
  );
};

export default Breadcrumbs;
