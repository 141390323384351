import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import axios, { all } from 'axios';
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import Team from "components/cards/ProfileThreeColGrid"
import Services from "components/features/ThreeColWithSideImageWithPrimaryBackground"
import AboutUs from "components/features/ThreeColSimple";
import GetStarted from "components/cta/GetStartedLight.js";

export default ({latestPost}) => {

  return (
    <>
    <AnimationRevealPage>
      <Hero />
      <AboutUs />
      {/*<Features 
        heading={<>Amazing <HighlightedText>Features</HighlightedText></>}
      />*/}
      <Services />
      <Team />
      {/*<MainFeature
        heading={<>Cloud built by and for <HighlightedText>Professionals</HighlightedText></>}
      />*/}
      <FeatureStats/>
      <Testimonial 
      />
      {/*<Pricing 
        heading={<>Flexible <HighlightedText>Plans</HighlightedText></>}
      />*/}
      {/*<FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
      />*/}
      <Blog latestPost={latestPost}
      />
      <GetStarted/>
    </AnimationRevealPage>
    </>
  );
}
