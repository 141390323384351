import React from "react";
import { useForm } from "react-hook-form";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import Breadcrumbs from "components/breadcrumb/Breadcrumbs";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col-reverse md:flex-row justify-between max-w-screen-xl mx-auto pb-20 md:py-12`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-12 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`pt-4 lg:pb-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-5xl sm:text-6xl lg:text-6xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8 bg-primary-700 hover:bg-orange-700 flex justify-center`
const MessageContainer = tw.div`flex flex-col justify-center items-center gap-3 mt-5 text-lg`
const Error = tw.p`text-red-700 text-sm mt-2 text-left`

export default ({
  subheading = null,
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span> with us.</>,
  description = "We are delighted to hear from you. Please leave a message and we will respond as soon as possible.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useForm({
    mode: "onTouched",
  });
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [Message, setMessage] = React.useState("");

  const onSubmit = async (data, e) => {
      console.log(data);
      await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data, null, 2),
      })
        .then(async (response) => {
          let json = await response.json();
          if (json.success) {
            setIsSuccess(true);
            setMessage(json.message);
            e.target.reset();
            reset();
          } else {
            setIsSuccess(false);
            setMessage(json.message);
          }
        })
        .catch((error) => {
          setIsSuccess(false);
          setMessage("Client Error. Please check the console.log for more info");
          console.log(error);
        });
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <div className='breadcrumb'>
              <Breadcrumbs/>   
          </div>  
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            {/*<Form action={formAction} method={formMethod}>
              <Input type="email" name="email" pattern="/^\S+@\S+$/i" placeholder="Your Email Address" />
              <Input type="text" name="name" placeholder="Full Name" />
              <Input type="text" name="subject" placeholder="Subject" />
              <Textarea name="message" placeholder="Your Message Here" />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>*/}
            {!isSubmitSuccessful && (
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <input
                            type="hidden"
                            value="a1683308-17a8-4972-bca6-fdc99fec326c"
                            {...register("access_key")}
                            />
                            <input
                            type="hidden"
                            {...register("subject")}
                            />
                            <input
                            type="hidden"
                            value="New contact message from blockace.co"
                            {...register("from_name")}
                            />
                            <input
                            type="checkbox"
                            id=""
                            className="hidden"
                            style={{ display: "none" }}
                            {...register("botcheck")}></input>

                            <Input
                                id="form_email"
                                type="email"
                                placeholder="Email Address"
                                name="email"
                                autoComplete="false"
                                className={`w-full px-4 py-3 border-2  rounded-md outline-none  focus:ring-4  ${
                                errors.email
                                    ? "border-red-600 focus:border-red-600 ring-red-100"
                                    : "border-gray-300 focus:border-cyan-600 ring-indigo-100"
                                }`}
                                {...register("email", {
                                required: "Enter your email",
                                pattern: {
                                    value: /^\S+@\S+$/i,
                                    message: "Please enter a valid email",
                                },
                                })}
                            />
                            {errors.email && (
                                <Error>{errors.email.message}</Error>
                            )}

                            <Input
                                id="form_name"
                                type="text"
                                placeholder="Full Name"
                                autoComplete="false"
                                className={`w-full px-4 py-3 border-2  rounded-md outline-none  focus:ring-4  ${
                                errors.name
                                    ? "border-red-600 focus:border-red-600 ring-red-100"
                                    : "border-gray-300 focus:border-cyan-600 ring-indigo-100"
                                }`}
                                {...register("name", {
                                required: "Enter your name",
                                maxLength: 80,
                                })}
                            />
                            {errors.name && (
                                  <Error>{errors.name.message}</Error>
                            )}

                            <Input
                                id="form_subject"
                                type="text"
                                placeholder="Subject"
                                name="Subject"
                                autoComplete="false"
                                className={`w-full px-4 py-3 border-2  rounded-md outline-none  focus:ring-4  ${
                                errors.Subject
                                    ? "border-red-600 focus:border-red-600 ring-red-100"
                                    : "border-gray-300 focus:border-cyan-600 ring-indigo-100"
                                }`}
                                {...register("Subject", {
                                required: "Enter your subject",
                                pattern: {
                                    value: "",
                                    message: "Please enter a subject",
                                },
                                })}
                            />
                            {errors.Subject && (
                                <Error>{errors.Subject.message}</Error>
                            )}
                            
                            <Textarea
                                name="message"
                                placeholder="Your Message"
                                className={`w-full px-4 py-3 border-2  rounded-md outline-none  h-36  focus:ring-4  ${
                                errors.message
                                    ? "border-red-600 focus:border-red-600 ring-red-100"
                                    : "border-gray-300 focus:border-cyan-600 ring-indigo-100"
                                }`}
                                {...register("message", { required: "Enter your message" })}
                            />
                            {errors.message && (
                                <Error>{errors.message.message}</Error>
                            )}

                            <SubmitButton type="submit">
                            {isSubmitting ? (
                                <svg
                                className="w-5 h-5 mx-auto text-white animate-spin"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24">
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) : (
                                "Send Message"
                            )}
                            </SubmitButton>
                        </Form>
                        )}
                        {isSubmitSuccessful && isSuccess  && (
                        <>
                            <MessageContainer>
                              <svg
                                width="72"
                                height="72"
                                className="text-cyan-200"
                                viewBox="0 0 100 100"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                d="M26.6666 50L46.6666 66.6667L73.3333 33.3333M50 96.6667C43.8716 96.6667 37.8033 95.4596 32.1414 93.1144C26.4796 90.7692 21.3351 87.3317 17.0017 82.9983C12.6683 78.6649 9.23082 73.5204 6.8856 67.8586C4.54038 62.1967 3.33331 56.1283 3.33331 50C3.33331 43.8716 4.54038 37.8033 6.8856 32.1414C9.23082 26.4796 12.6683 21.3351 17.0017 17.0017C21.3351 12.6683 26.4796 9.23084 32.1414 6.88562C37.8033 4.5404 43.8716 3.33333 50 3.33333C62.3767 3.33333 74.2466 8.24998 82.9983 17.0017C91.75 25.7534 96.6666 37.6232 96.6666 50C96.6666 62.3768 91.75 74.2466 82.9983 82.9983C74.2466 91.75 62.3767 96.6667 50 96.6667Z"
                                stroke="currentColor"
                                strokeWidth="4"
                                />
                              </svg>
                              <h3 className="py-5 text-xl text-cyan-200">Success</h3>
                              <p className="text-white md:px-3">{Message}</p>
                              <SubmitButton
                                  className="mt-6 w-32 h-12 back-btn"
                                  onClick={() => reset()}>
                                  Go back
                              </SubmitButton>
                            </MessageContainer>
                        </>
                        )}

                        {isSubmitSuccessful && !isSuccess && (
                        <MessageContainer>
                            <svg
                            width="72"
                            height="72"
                            viewBox="0 0 100 100"
                            className="text-red-400"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M27.9995 69C43.6205 53.379 52.3786 44.621 67.9995 29M26.8077 29L67.9995 69M48.2189 95C42.0906 95 36.0222 93.7929 30.3604 91.4477C24.6985 89.1025 19.554 85.6651 15.2206 81.3316C10.8872 76.9982 7.44975 71.8538 5.10454 66.1919C2.75932 60.53 1.55225 54.4617 1.55225 48.3333C1.55225 42.205 2.75932 36.1366 5.10454 30.4748C7.44975 24.8129 10.8872 19.6684 15.2206 15.335C19.554 11.0016 24.6985 7.56418 30.3604 5.21896C36.0222 2.87374 42.0906 1.66667 48.2189 1.66667C60.5957 1.66667 72.4655 6.58333 81.2172 15.335C89.9689 24.0867 94.8856 35.9566 94.8856 48.3333C94.8856 60.7101 89.9689 72.58 81.2172 81.3316C72.4655 90.0833 60.5957 95 48.2189 95Z"
                                stroke="CurrentColor"
                                strokeWidth="4"
                            />
                            </svg>

                            <h3 className="text-xl text-red-400 py-7">
                            Oops, Something went wrong!
                            </h3>
                            <p className="text-white md:px-3">{Message}</p>
                            <SubmitButton className="mt-6 w-32 h-12 back-btn" onClick={() => reset()}>
                            Try Again
                            </SubmitButton>
                        </MessageContainer>
                        )}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
