import React,  {useState, useEffect} from "react";
import tw from "twin.macro";
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "components/headers/light.js";
import { HashLink } from 'react-router-hash-link';
import scrollWithOffset from "helpers/ScrollWithOffset";

import './NavBar.css'

const Header = tw(HeaderBase)`max-w-none`;

export default ({buttonRounded = true}) => {
    const [top, setTop] = useState(true);

    useEffect(() => {
      const scrollHandler = () => {
        window.scrollY > 0 ? setTop(false) : setTop(true)
      };
      window.addEventListener('scroll', scrollHandler);
      return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);

    const buttonRoundedCss = buttonRounded && tw`rounded-full`;

    const navLinks = [
      <NavLinks key={1}>
        <HashLink smooth scroll={scrollWithOffset} to="/#about-us" className="hash-link">
          <NavLink>About</NavLink>
        </HashLink>
        <HashLink smooth scroll={scrollWithOffset} to="/#services" className="hash-link">
          <NavLink>Services</NavLink>
        </HashLink>
        <HashLink smooth scroll={scrollWithOffset} to="/#team" className="hash-link">
          <NavLink>Team</NavLink>
        </HashLink>
        <HashLink smooth scroll={scrollWithOffset} to="/#network" className="hash-link">
          <NavLink>Network</NavLink>
        </HashLink>
        <HashLink smooth scroll={scrollWithOffset} to="/#clients-partners" className="hash-link">
          <NavLink>Clients & Partners</NavLink>
        </HashLink>
        <HashLink smooth scroll={scrollWithOffset} to="/contact" rel="noopener noreferrer" className="hash-link">
          <NavLink>Contact</NavLink>
        </HashLink>
      </NavLinks>,
      <NavLinks key={2}>
        <PrimaryLink css={buttonRoundedCss} href="/posts">
          Blog
        </PrimaryLink>
      </NavLinks>
    ];
    return (
      <>
        <div className={`nav-bar ${!top ? "nav-scroll" : ""}`}>
          <Header links={navLinks} />
        </div>
      </>
    );
  };