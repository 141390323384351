import React, { useState, useEffect } from "react";
import axios from 'axios';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import MoonLoader from "react-spinners/MoonLoader";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import Breadcrumbs from "components/breadcrumb/Breadcrumbs";

const Container01 = tw(Container)`min-h-96 flex md:flex-row flex-col items-center justify-center`
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-primary-700 mt-4`;
const Posts = tw.div`mt-0 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 md:mt-16 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Title} {
        ${tw`text-3xl`}
      }
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${PostImage} {
        ${tw`sm:h-auto mt-0 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const PostImage = tw.img`w-full mx-auto rounded rounded-b-none aspect-video h-auto lg:h-auto`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div`text-sm mt-3 leading-loose text-gray-600 font-medium`;
const ContentWithPaddingXl1 = tw(ContentWithPaddingXl)`md:py-12 py-12`

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

const MetaContainer1 = styled.div`${tw`flex items-center gap-2 mt-10`}
      p {
        ${tw`font-semibold text-primary-700`}
      }
`;
const Meta1 = styled.a`
  ${tw`cursor-pointer bg-gray-200 p-2 rounded text-primary-500 border border-primary-500 font-medium text-base flex items-center leading-none last:mr-0 inline-block hover:bg-primary-700 hover:text-white hover:border-primary-700`}
  
  svg {
    ${tw`w-4 h-4 mr-1`}
  }


`

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#3b82f6",
};

export default ({
  headingText = "Latest Posts",
  categories
}) => {

  const [tabIndex, setTabIndex] = useState(0);
  
  const [error, setError] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [posts, setPosts] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [visible, setVisible] = useState(7);

  const [activeIndex, setActiveIndex] = useState(-1)

  const onLoadMoreClick = () => {
    setVisible(v => v + 6);
  };

  useEffect(() => {
    if (categoryName) {
      axios
      .get(`${process.env.REACT_APP_BACKEND}/api/posts?populate=*&pagination[limit]=${visible}&sort[0]=createdAt:desc&filters[$and][0][categories][category_name][$eq]=${categoryName}`)
      .then(({ data }) => {setPosts(data.data); setIsLoading(false);})
      .catch((error) => setError(error));
    } else {
      axios
      .get(`${process.env.REACT_APP_BACKEND}/api/posts?populate=*&pagination[limit]=${visible}&sort[0]=createdAt:desc`)
      .then(({ data }) => {setPosts(data.data); setIsLoading(false);})
      .catch((error) => setError(error));
    }
    
  }, [visible, categoryName]);
  
  const handleClick = (value) =>{
    setActiveIndex(value)   
  }

  return (
    <AnimationRevealPage>
      <Container01>
        {!isLoading ? <ContentWithPaddingXl1>
          <div className='breadcrumb'>
              <Breadcrumbs/>   
          </div>  
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <MetaContainer1>
                <p>By Category:</p>
                <Meta1 onClick={() => {setCategoryName(null); handleClick();}} href='' className={activeIndex === -1 ? "category-selected" :""}>
                    <div>All Posts</div>
                </Meta1>
                {categories.map((category, index) => (
                  <Meta1 key={index} onClick={() => {setCategoryName(category.attributes.category_name); handleClick(index);}} className={index === activeIndex ? "category-selected" :""} id={`button-${index}`}>
                    <div>{category.attributes.category_name}</div>
                  </Meta1>
                ))}
          </MetaContainer1>
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={index === 0}>
                <Post className="group" as="a" href={`/posts/${post.attributes.post_uid}`}>
                  <PostImage src={post.attributes.post_banner.data.attributes.url} />
                  <Info>
                    <Category>{post.attributes.categories.data[0].attributes.category_name}</Category>
                    <CreationDate>{new Date(post.attributes.publishedAt).toLocaleDateString("en-GB", {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </CreationDate>
                    <Title>{post.attributes.post_title}</Title>
                    {post.attributes.post_summary && <Description>{post.attributes.post_summary}</Description>}
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          <ButtonContainer>
            <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
          </ButtonContainer>
        </ContentWithPaddingXl1>
        :
        <MoonLoader 
          cssOverride={override}
          color="#3b82f6"
          loading={true}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      }
      </Container01>
    </AnimationRevealPage>
  );
};
