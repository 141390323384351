import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import BDIcon from "images/svg/growth-chart.svg";
import MarketingIcon from "images/svg/speaker.svg";
import UAIcon from "images/svg/profile.svg";
import CreativeIcon from "images/svg/bulb.svg";

const Container = tw.div`relative bg-primary-700 -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/2 max-w-md`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center justify-start sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0 mx-auto`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 w-full flex flex-col items-center`}
  }

  .title {
    ${tw`tracking-wider text-neon-700 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug text-center`}
  }

  .servicesList {
    ${tw`mt-4 w-full h-auto`}
  }

  .service {
    ${tw`mt-2 font-normal text-white text-center w-full h-auto`}
  }
`;

export default ({
  cards = null,
  heading = "Our Services",
  subheading = "",
  description = "Grow3 provides the one-stop solution for individuals, organizations, and projects in the cryptocurrency and web3 fields."
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: BDIcon,
      title: "Business Development",
      description: "We strictly only deal with vendors that provide top notch security infrastructure.",
      services: ["Market Research", "KOLs & Influencers Network", "Market Maker Strategy", "Top-tier Exchanges Listing"]
    },
    { imageSrc: MarketingIcon,
      title: "Marketing & Ads",
      description: "We strictly only deal with vendors that provide top notch security infrastructure.",
      services: ["Social Media Marketing", "Press Release & Media Relations", "Performance & Growth Hacking", "Digital Advertising"]
    },
    { imageSrc: UAIcon,
      title: "User Acquisition",
      description: "We strictly only deal with vendors that provide top notch security infrastructure.",
      services: ["Community Building & Management", "Airdrop & Bounty Campaigns", "User Acquisition Events", "Referral / Affiliate Programs"]
    },
    { imageSrc: CreativeIcon,
      title: "Creative & Production",
      description: "We strictly only deal with vendors that provide top notch security infrastructure.",
      services: ["Website Development", "Content Creation", "Graphic Design & Video Production", "Brand & Visual Identity"]
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container id="services">
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => 
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
               {/*<p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>*/}
              </span>
              <span className="servicesList">
                 {card.services.map((service, j) => 
                    <div className="service" key={j}>
                      {service}
                    </div>
                 )}
              </span>
            </Card>
          </Column>
        )}
      </ThreeColumnContainer>
    </Container>
  );
};
