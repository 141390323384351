import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase} from "components/misc/Headings.js";

import logo from "../../assets/images/Grow3-horizontal.png";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import TargetIcon from "../../images/target.svg";
import ValueIcon from "../../images/diamond.svg";


const Container = tw.div`relative py-20 md:mx-20 md:mb-20 md:pb-40 md:bg-gray-100 rounded-lg`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-0 md:py-0`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Subheading = tw(SubheadingBase)`text-gray-700 font-medium w-full md:w-1/2 mx-auto mt-6 text-justify`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-5 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-600 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-700`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const Logo = styled.div`
img {
  ${tw`z-20 w-32 md:w-64 mx-auto mt-8`}
}
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ( { subheading = "Grow3 provides services as demand for all cryptocurrency and web3 projects with diversified services. At Grow3, We help web3 and cryptocurrency projects expand their reputation and user base to the Vietnam and Southeast Asia markets. We provide consulting and support in building brand, community, and product development. Grow3 also connects projects with our partners in publishing, advertising, technology, investment, and exchange listings.",} ) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

 


  const cards = [
    {
      imageSrc: TargetIcon,
      title: "Our Mission",
      description: "Grow3 aims to provide the one-stop solution for Web3 crypto projects to expand business in Vietnam and Southeast Asia markets."
    },
    {
      imageSrc: ValueIcon,
      title: "Our Value",
      description: "Grow3 brings efficiency and value-added by deploying tailor-made campaigns to increase your project branding exposure with the target audiences, get mass adoption, and onboard millions of users."
    },
  ];

  return (
    <Container id="about-us">
      <Heading>About <span tw="text-primary-600">Us</span></Heading>
      <div className="align-last">
        <Subheading>{subheading}</Subheading>
      </div>
      {/*<Logo>
        <img src={logo} alt="grow3-logo" />
      </Logo>*/}
      <ThreeColumnContainer>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
