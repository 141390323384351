import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";

const Container = styled.div`${tw`relative`}
  .view-all-btn {
    ${tw`flex justify-center`}
  }

  .link {
    ${tw`mt-10 inline-flex items-center pt-5 text-lg font-semibold text-primary-500 leading-none hocus:text-primary-900 ease-in duration-200`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap mt-12`;
const Column = tw.div`mt-8 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`
]);

const PostImage = tw.img`w-full mx-auto mt-10 rounded rounded-b-none aspect-video h-auto lg:h-auto`;

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold bg-primary-500`}
`

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({
  subheading = "Blog",
  heading = <>We Love <span tw="text-primary-500">Sharing.</span></>,
  description = "Keep up with our latest news and featured posts.",
  latestPost

}) => {

  const blogPost = latestPost
  //prop is Blog Posts

  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          <HeadingDescription>{description}</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPost.map((post, index) => (
            <Column key={index}>
              <Card>
                <PostImage src={post.attributes.post_banner.data.attributes.url} />
                <Details>
                  <MetaContainer>
                    <Meta>
                      <UserIcon />
                      <div>{post.attributes.authors.data[0].attributes.author_name}</div>
                    </Meta>
                    <Meta>
                      <TagIcon />
                      <div>{post.attributes.categories.data[0].attributes.category_name}</div>
                    </Meta>
                  </MetaContainer>
                  <Title>{post.attributes.post_title}</Title>
                  <Description>{post.attributes.post_summary}</Description>
                  <Link href={`/posts/${post.attributes.post_uid}`}>Read Post</Link>
                </Details>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
        <div className="view-all-btn">
          <a className="link group" href="/posts">
                <span>View All</span>
                <ArrowRightIcon className="icon" tw="group-hover:translate-x-1 ease-in duration-200"/>
          </a>
        </div>

      </Content>
    </Container>
  );
};
