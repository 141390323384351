import React, {useState, useEffect} from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css } from "styled-components/macro"; //eslint-disable-line
import axios from "axios";

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

import ComponentRenderer from "ComponentRenderer.js";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ServiceLandingPage from "home/ServiceLandingPage";
import AboutUs from "pages/AboutUs";
import ContactUs from "pages/ContactUs";
import BlogIndex from "pages/BlogIndex";
import NavBar from "components/navbar/NavBar";
import Footer from "components/footers/FiveColumnDark";
import NotFound from 'components/404/NotFound';

import ScrollToTop from "helpers/ScrollToTop";
import PostDetail from "pages/PostDetail";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  const [latestPost, setLatestPost] = useState([]);
  const [categories, setCategories] = useState([])
  const [error, setError] = useState()

  useEffect(() => {
    async function fetchData() {
    axios
      .get(`${process.env.REACT_APP_BACKEND}/api/posts?populate=*&pagination[limit]=6&sort[0]=createdAt:desc`)
      .then(({ data }) => setLatestPost(data.data))
      .catch((error) => setError(error));
  }
  
  fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
    axios
      .get(`${process.env.REACT_APP_BACKEND}/api/categories?populate=*&sort[0]=createdAt:desc`)
      .then(({ data }) => setCategories(data.data))
      .catch((error) => setError(error));
  }
  
  fetchData();
  }, []);

  return (
    <>
      <GlobalStyles />
      <Router>
        <NavBar />
        <ScrollToTop />
        <Routes>
          <Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />} />
          <Route path="/components/:type/:name" element={<ComponentRenderer />} />
          <Route exact path="/" element={<ServiceLandingPage latestPost={latestPost} />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/posts" element={<BlogIndex categories={categories}/>} />
          <Route path="/posts/categories/:id" element={<BlogIndex/>} />
          <Route path="/posts/:id" element={<PostDetail latestPost={latestPost}/>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}
