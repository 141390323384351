import React, {useState, useEffect }from 'react'
import { useParams } from 'react-router-dom'
import axios, { all } from 'axios';
import parse from 'html-react-parser';
import { motion } from "framer-motion";
import {Helmet} from "react-helmet";

import MoonLoader from "react-spinners/MoonLoader";
import { FacebookShareButton, TelegramShareButton, TwitterShareButton, EmailShareButton } from "react-share";
import { FacebookIcon, TelegramIcon, XIcon, EmailIcon } from "react-share";

import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";

import NotFound from 'components/404/NotFound';
import Breadcrumbs from 'components/breadcrumb/Breadcrumbs';


const HeadingRow = tw.div`flex`;
const Container01 = tw(Container)`min-h-screen w-full flex md:flex-row flex-col items-start justify-center md:pt-12 md:pb-20 px-6`
const ContentWithPaddingXl01 = tw(ContentWithPaddingXl)`max-w-screen-md mx-0 w-full md:px-10 md:py-0 pt-10`
const Heading = tw(SectionHeading)`text-gray-900 mb-5 font-medium mt-10 text-left text-2xl md:text-4xl w-full`;
const Summary = tw.p`text-gray-700 text-base w-full py-4 px-5 bg-gray-200 italic rounded-lg mt-10`
const PostContent = styled.div`
${tw`mt-8`}

`
const AuthorShare = styled.div`
  ${tw`mt-6 flex w-full justify-between items-center`}

  .author-container {
    ${tw`flex items-center gap-2 md:gap-3`}
  }

  .author-img {
    ${tw`w-10 h-10 md:w-12 md:h-12 rounded-full border-2 border-primary-300 `}
  }

  .author-name {
    ${tw`font-semibold text-gray-800 `}
  }

  .published-date {
    ${tw`font-medium text-xs md:text-sm text-gray-600 `}
  }
  
  
  .share-container {
    ${tw`flex gap-1 pl-4 md:gap-2`}
  }
`

const PostImage = tw.img`w-full mx-auto rounded-lg mt-0`;

//For Recent Posts Col
const Title = tw.h5`mt-6 text-xl font-bold transition duration-300 group-hover:text-primary-500`;
const AuthorName = tw.h6`font-semibold text-lg`;
const ColHeading = tw(SectionHeading)`text-gray-700 mb-5 font-medium mt-10 text-left text-3xl w-full`;
const PostsContainer = tw.div`mt-6 flex flex-col sm:flex-col sm:justify-between lg:justify-start bg-gray-100 p-4 rounded`;
const Post = tw(motion.a)`block sm:max-w-sm cursor-pointer mb-16 last:mb-0 sm:mb-0 sm:odd:mr-8 lg:mr-8 xl:mr-16`;
const Image = styled(motion.div)(props => [
  `background-image: url("${props.$imageSrc}");`,
  tw`h-64 bg-cover bg-center rounded`
]);
const PostTextContainer = tw.div`mr-3`

const RecentPostsContainer = styled.div`
  ${tw`mt-0 md:mt-24 lg:mt-0 w-full mb-10 md:w-1/3 lg:w-1/4 xl:w-1/5 md:ml-4`}
  ${PostsContainer} {
    ${tw`flex flex-wrap lg:flex-col`}
  }
  ${Post} {
    ${tw`flex justify-between mb-8 max-w-none w-full lg:odd:pr-0 mr-0`}
  }
  ${Title} {
    ${tw`text-base xl:text-lg mt-0 mr-4 lg:max-w-xs`}
  }
  ${AuthorName} {
    ${tw` text-sm text-secondary-100 font-normal leading-none`}
  }
  ${Image} {
    ${tw`h-20 w-20 flex-shrink-0`}
  }
`;

const MetaContainer = tw.div`flex items-center mt-3`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
}`

const MetaContainer1 = tw.div`flex items-center mb-4 gap-2`;
const Meta1 = styled.div`
  ${tw`bg-gray-200 p-1 rounded text-primary-500 border border-primary-500 font-medium text-sm flex items-center leading-none last:mr-0 inline-block`}
  svg {
    ${tw`w-4 h-4 mr-1`}
}`

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#3b82f6",
};

function PostDetail({ latestPost }) {
    const [post, setPost] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState()

    const postId = useParams();
  
    useEffect(() => {
      async function fetchData() {
        axios
        .get(`${process.env.REACT_APP_BACKEND}/api/posts?filters[post_uid][$eq]=${postId.id}&populate=deep`)
        .then(({ data }) => 
          {
            setPost(data.data);
            setIsLoading(false);
          })
        .catch((error) => setError(error));
      }

    fetchData();
  
    }, []);

    const shareUrl = `https://grow3.pro/posts/${postId.id}`
    
  return (
    <Container01>
        {post[0] ?         
        <Helmet>
            <meta charSet="utf-8" />
            <title>Grow3 - {post[0].attributes.post_title}</title>
            <link rel="canonical" href="http://mysite.com/example" />
            <meta name="description" content="Helmet application" />
            <meta property="og:site_name" content="Grow3" />
            <meta property="og:title" content="Onboarding Millions of Web3 Users" />
            <meta
              property="og:image"
              content={post[0].attributes.post_banner.data.attributes.url}
            />
            <meta name="twitter:image"
              content={post[0].attributes.post_banner.data.attributes.url}  />
        </Helmet> : null}

        {!isLoading ? 
          <ContentWithPaddingXl01>
            {!post[0] ? <NotFound /> :

            <div>
              <div className='breadcrumb'>
                <Breadcrumbs prop={post[0].attributes.post_title}/>   
              </div>      
              <HeadingRow>
                <Heading>{post[0].attributes.post_title}</Heading>
              </HeadingRow>
              <MetaContainer1>
                {post[0].attributes.categories.data.map((category, index) => (
                  <Meta1 key={index}>
                    <TagIcon />
                    <div>{category.attributes.category_name}</div>
                  </Meta1>
                ))}
              </MetaContainer1>
              <PostImage src={post[0].attributes.post_banner.data.attributes.url}  />
              <AuthorShare>
                <div className='author-container'>
                  <div>
                     <img src={post[0].attributes.authors.data[0].attributes.author_avatar.data.attributes.formats.small.url} className='author-img'/>
                  </div>
                  <div>
                    <p className='author-name'>{post[0].attributes.authors.data[0].attributes.author_name}</p>
                    <p className='published-date'>on {new Date(post[0].attributes.publishedAt).toLocaleDateString("en-GB", {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </p>
                  </div>
                </div>
                <div className="share-container">
                  <FacebookShareButton
                    url={shareUrl}
                    hashtag={""}
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton
                    title={post[0].attributes.post_title}
                    url={shareUrl}
                    hashtags={[]}
                  >
                    <XIcon size={32} round />
                  </TwitterShareButton>
                  <TelegramShareButton
                    title={"test"}
                    url={shareUrl}
                    hashtags={["hashtag1", "hashtag2"]}
                  >
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>
                  <EmailShareButton
                    title={""}
                    url={shareUrl}
                    hashtags={""}
                  >
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                </div>
              </AuthorShare>
              <Summary>{post[0].attributes.post_summary}</Summary>
              <PostContent>
                {post[0].attributes.post_content ? 
                  <div className='markdown-parser'>
                      {parse(post[0].attributes.post_content)}
                  </div> 
                  : 
                  <div>...</div>
                }
              </PostContent>
            </div>
            }

          </ContentWithPaddingXl01>
     
          :<MoonLoader 
            cssOverride={override}
            color="#3b82f6"
            loading={true}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        }
     
        <RecentPostsContainer>
          <ColHeading>Recent Posts</ColHeading>
          <PostsContainer>
            {latestPost.map((post, index) => (
            <Post key={index} href={`/posts/${post.attributes.post_uid}`} className="group">
              <PostTextContainer>
                <Title>{post.attributes.post_title}</Title>
                <MetaContainer>
                    <Meta>
                      <AuthorName>{post.attributes.authors.data[0].attributes.author_name}</AuthorName>
                    </Meta>
                    <Meta>
                      <TagIcon />
                      <div>{post.attributes.categories.data[0].attributes.category_name}</div>
                    </Meta>
                </MetaContainer>
              </PostTextContainer>
              <Image $imageSrc={post.attributes.post_banner.data.attributes.url} />
            </Post>
            ))}
          </PostsContainer>
        </RecentPostsContainer>

        
    </Container01>
  )
}

export default PostDetail