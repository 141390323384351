import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";

const Container = tw(ContainerBase)`my-8 lg:my-10 bg-primary-700 text-gray-100 -mx-8 px-8 py-10`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-neon-700 font-semibold text-center`;
const Description = tw(SectionDescription)`text-gray-400 text-center mx-auto max-w-screen-md`;

const StatsContainer = tw.div`mt-8 flex flex-col md:flex-row items-center md:items-start  flex-wrap max-w-screen-xl justify-between mx-auto`
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`
const StatKey = tw.div`text-xl font-medium text-neon-700`
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`
const StatDetail = tw.div`text-sm text-gray-300 text-center mx-auto max-w-72 mt-2`

export default ({
  subheading = "Efficiency - Evolution - Embracement",
  heading = " Your Trusted Web3 Companion",
  description = "We have the resources, strategies and deep understanding of the web3 market to help your project spread, grow and succeed.",
  stats = [
    {
      key: <>Countries in<br />SEA & East Asia</>,
      value: "7+",
      details: "With local experts in each country"
    },
    {
      key: <>Potential Users<br />in Reach</>,
      value: "120M+",
      details: "Users in Vietnam, Thailand, Indonesia, Philippines, Singapore, Malaysia, China, South Korea."
    },
    {
      key: <>Top KOLs<br />& Communities</>,
      value: "200+",
      details: "That effectively cover the entire SEA region's audiences"
    },
    {
      key: <>News & Publishing<br />Partners</>,
      value: "50+",
      details: "Including Forbes, Business Insider, Yahoo Finance, Cointelegraph, CoinDesk, MarketWatch, and top leading local media."
    }
  ]
}) => {
  return (
    <Container id="network">
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <StatsContainer>
          {stats.map((stat, index) => (
            <Stat key={index}>
              <StatValue>{stat.value}</StatValue>
              <StatKey>{stat.key}</StatKey>
              <StatDetail>{stat.details}</StatDetail>
            </Stat>
          ))}
        </StatsContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
